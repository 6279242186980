<template>
  <div class="overflow-y-scroll scrollbar-hide h-full">
    <div class="relative m-auto z-10 mb-48">
      <div class="top-profile">
        <div class="flex justify-between items-start">
          <router-link class="pr-8" :to="{name : 'HomeRealEstate'}"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
            <defs>
              <filter id="Rectangle_3158" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
              </filter>
            </defs>
            <g id="Group_12961" data-name="Group 12961" transform="translate(-308 -28)">
              <g id="Group_4717" data-name="Group 4717" transform="translate(317 33.547)">
                <g id="Group_4426" data-name="Group 4426" transform="translate(0 0.452)">
                  <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_3158)">
                    <rect id="Rectangle_3158-2" data-name="Rectangle 3158" width="25" height="25" rx="12.5" transform="translate(9 6)" fill="#ff7400"/>
                  </g>
                </g>
              </g>
              <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(317.705 34.376)" fill="#fff"/>
            </g>
          </svg>
          </router-link>

        </div>
        <div class="bg-profile"></div>
        <div class="relative">
          <img :src="baseURL + user.real_estate.logo" alt="" class="rounded-full w-24 mx-auto">
          <div class="expire" v-if="user.subscription_info">{{user.subscription_info.data.days_left}}<span>روز اعتبار</span></div>
        </div>
        <h5>{{user.real_estate.name}}</h5>
        <div class="w-10/12 mx-auto py-4 mt-6 rounded-full text-white bg-pink-2">تمدید اشتراک شما</div>
        <div>
          <ul class="subscription">
            <li>
              <div class="flex w-full">
                <div class="relative">
                  <span class="subscription-month">3</span>
                  <img src="../../assets/images/subsciption.png" alt="sub">
                </div>
                <p class="mt-3">اشتراک سه ماه</p>
              </div>
              <div class="price_wrapper">
                <div class="flex justify-between">
                  <p>قیمت اشتراک</p>
                  <p class="price-line"
                     :class="[isFirstPay ? 'line-through decoration-red-600' : '']"
                  >1,200,000<span> تومان </span></p>
                </div>
                <div class="flex justify-between mt-3" v-if="isFirstPay">
                  <p>عضویت جدید</p>
                  <p class="price">450,000<span> تومان </span></p>
                </div>
                <div class="mt-8 mb-8 text-left">
                  <a class=button-price
                     :class="!isFirstPay ? 'bg-orange-btn' : ''"
                     @click="handleBuy(4)">انتخاب و ادامه</a>
                </div>
              </div>
            </li>
            <li>
              <div class="flex w-full">
                <div class="relative">
                  <span class="subscription-month">6</span>
                  <img src="../../assets/images/subsciption.png" alt="sub">
                </div>
                <p class="mt-3">اشتراک شش ماه</p>
              </div>
              <div class="price_wrapper">
                <div class="flex justify-between">
                  <p>قیمت اشتراک</p>
                  <p class="price-line"
                     :class="[isFirstPay ? 'line-through decoration-red-600' : '']">
                    2,400,000<span> تومان </span></p>
                </div>
                <div class="flex justify-between mt-3" v-if="isFirstPay">
                  <p>عضویت جدید</p>
                  <p class="price">850,000<span> تومان </span></p>
                </div>
                <div class="mt-8 mb-8 text-left">
                  <a class=button-price
                     :class="!isFirstPay ? 'bg-orange-btn' : ''"
                     @click="handleBuy(5)">انتخاب و ادامه</a>
                </div>
              </div>
            </li>
            <li>
              <div class="flex w-full">
                <div class="relative">
                  <span class="subscription-month">12</span>
                  <img src="../../assets/images/subsciption.png" alt="sub">
                </div>
                <p class="mt-3">اشتراک یک ساله</p>
              </div>
              <div class="price_wrapper">
                <div class="flex justify-between">
                  <p>قیمت اشتراک</p>
                  <p class="price-line"
                     :class="[isFirstPay ? 'line-through decoration-red-600' : '']">
                    4,800,000<span> تومان </span></p>
                </div>
                <div class="flex justify-between mt-3" v-if="isFirstPay">
                  <p>عضویت جدید</p>
                  <p class="price">1,600,000<span> تومان </span></p>
                </div>
                <div class="mt-8 mb-8 text-left">
                  <a class=button-price
                     :class="!isFirstPay ? 'bg-orange-btn' : ''"
                     @click="handleBuy(6)">انتخاب و ادامه</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../plugins/axios'
export default {
  name: "Subscription",
  data(){
    return{
      user : null,
      baseURL : this.$store.state.general.baseURL,
      url : this.$store.state.general.url,
      isFirstPay : true
    }
  },
  methods:{
    updateUser(data){
      this.user = data
    },

    handleBuy(id){
       axios.post('/subscription/pay',{plan_id : id}).then(({data})=>{
        window.open(data.data.payment.action)
      })
    }
  },
  async mounted() {
    const {data} = await axios.get('/users')
    this.user = data.data
    console.log()
    this.isFirstPay = !this.user.never_buy_subscription

  }
}


</script>

<style scoped>

</style>